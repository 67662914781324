<template>
  <div class="row">
    <input-text
      v-model="filtro.codigo"
      class="col-12 col-md-6"
      :label="$t('modulos.classificacao_fiscal.filtro.codigo')"
      :placeholder="$t('modulos.classificacao_fiscal.filtro.codigo')"
      em-filtro
    />
    <input-text
      v-model="filtro.aliquotaIpi"
      class="col-12 col-md-6"
      :label="$t('modulos.classificacao_fiscal.filtro.aliquotaIpi')"
      :placeholder="$t('modulos.classificacao_fiscal.filtro.aliquotaIpi')"
      type="number"
      :max="100"
      :min="0"
      em-filtro
    />
    <input-text
      v-model="filtro.descricao"
      descricao
      class="col-12"
      :label="$t('modulos.classificacao_fiscal.filtro.descricao')"
      :placeholder="$t('modulos.classificacao_fiscal.filtro.descricao')"
      em-filtro
    />
  </div>
</template>
<script>
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
    };
  },
};
</script>
