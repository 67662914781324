<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.classificacao_fiscal.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.classificacao_fiscal.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('ClassificacaoFiscal', 'Inserir')"
      :sem-filtro="!buscarPermissao('ClassificacaoFiscal', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('ClassificaçãoFiscal', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      sem-selecionar-todos
      :mostrar-seletor="buscarPermissao('ClassificacaoFiscal', 'Deletar')"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          v-if="buscarPermissao('ClassificacaoFiscal', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
      <template v-slot:codigo="{ slotProps }">
        <div>
          {{
            slotProps.data.codigo | VMask(mascaraCodigo(slotProps.data.codigo))
          }}
        </div>
      </template>
      <template v-slot:aliquotaIpi="{ slotProps }">
        <div class="alinhar-direita">
          {{ slotProps.data.aliquotaIpi | formatarNumeroComPrecisao }}
        </div>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import helpers from '@/common/utils/helpers';
import ClassificacaoFiscalService from '@common/services/cadastros/ClassificacaoFiscalService';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import Filtro from './filtro.vue';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.classificacao_fiscal.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'aliquotaIpi',
            text: this.$t('modulos.classificacao_fiscal.tabela.aliquotaIpi'),
            sortable: true,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.classificacao_fiscal.tabela.descricao'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 0,
        porPagina: 10,
        colunaOrdenada: '',
      },
      filtroAplicado: { filter: '' },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.classificacao_fiscal.titulos.listagem')
    );
    this.listarRegistros();
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'ClassificacaoFiscal', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    mascaraCodigo(codigo) {
      if (codigo.length > 8) return 'NNNN.NN.NN.NN';
      return 'NNNN.NN.NN';
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        sort: this.colunaOrdenada,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      ClassificacaoFiscalService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        ClassificacaoFiscalService.excluir(this.tabela.selecionados[0].id)
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(
              this.$t(`modulos.classificacao_fiscal.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'classificacao-fiscal-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'classificacao-fiscal-editar',
        params: { id: item.id },
      });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
<style lang="scss">
.alinhar-direita {
  display: flex;
  justify-content: right;
}
</style>
